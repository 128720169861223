.bounceInUp {
    position: relative;
    top: 0;
    opacity: 1;
    :global {
        animation: BounceInUp 0.3s ease-in both;
    }
}

.page {
    position: absolute;
    left: 15px;
    right: 15px;
  }
  
  .page-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  
  .page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  
  .page-exit {
    opacity: 1;
    transform: scale(1);
  }
  
  .page-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        display: none;
    }
}

@keyframes :global(BounceInUp) {
    0%,
    60%,
    75%,
    90% {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        position: relative;
        top: 100px;
        opacity: 0;
    }
    60% {
        top: -20px;
        opacity: 0.6;
    }
    75% {
        top: 10px;
        opacity: 0.75;
    }
    90% {
        position: relative;
        top: -5px;
        opacity: 0.9;
    }
}

@keyframes :global(fadeInUp) {
    0% {
        position: relative;
        top: 100px;
        opacity: 0;
    }
}

@keyframes :global(fadeIn) {
    0% {
        opacity: 0;
    }
}
