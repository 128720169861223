.root {
    margin: 16px;
}

.title {
    display: flex;
    padding: 0 4px 0 16px;
}

.iconTitle {
    padding-top: 16px;
}

.textTitle {
    padding-top: 16px;
}

.cancel {
    padding-top: 4px;
}
.content {
    margin: 0 48px 12px 72px;
}

.actions {
    margin: 0 48px 0 64px;
    padding-bottom: 10px;
}
