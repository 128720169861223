.outer {
    position: relative;
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.isActive {
    opacity: 1;
    transition: all 0.5s cubic-bezier(0.4, 0.0, 0.2, 1);
}

.message {
    position: absolute;
    width: 300px;
    top: 45px;
    right: 16px;
    padding: 8px;
}