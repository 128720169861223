.root {
    display: grid;
    position: relative;
    overflow: hidden;
    height: 100%;
}

.wrap {
    position: relative;
    overflow: hidden;
    max-height: 100%;
}

.nav {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    //padding: 0 16px 16px 16px;
    padding: 16px 4px 16px 4px;
}

.viewFull {
    width: 100vw;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
}

.view {
    // exibe parte do próximo
    //width: 351px;
    pointer-events: none;
    width: calc(100vw - 24px);
    padding: 0 4px;
    height: calc(100% - 34px);
    position: absolute;
    left: 0;
    top: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
}

.container {
    width: 100%;
    height: 100%;
}

.dotButton {
    display: inline-block;
    margin: 8px;
    padding: 8px;
    border-radius: 50%;
    color: var(--color-white-90);
}

.arrowButton {
    color: var(--color-primary);
}

.noTouchable {
    transition: 'all 300ms ease-in';
}

.dot {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--color-white-90);
}

.isActive {
    background-color: var(--color-primary);
}

.isNavDot {
    justify-content: center;
}

.isNavArrow {
    justify-content: space-between;
}

.swapped {
    & > .view,
    & > .viewFull {
        transition: all 300ms cubic-bezier(0.4, 0, 1, 1) 0ms;
    }
}
