.root {
    width: 100%;
}

.content {
    display: flex;
    padding: 6px 16px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
    border-radius: 4px;
    letter-spacing: 0.01071em;
}

.icon {
    display: flex;
    opacity: 0.9;
    padding: 7px 0;
    font-size: 22px;
    margin-right: 12px;
}

.message {
    padding: 8px 0;
}

.action {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-left: 16px;
}
