.root {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 16px;
    text-align: center;
}

.body {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.img {
    margin: 0 auto;
    width: calc(100% / 4);
}

.fill {
    fill: var(--color-primary);
}
