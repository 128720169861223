.root {
    position: relative;
    width: inherit;
    height: inherit;
}

.btnHint {
    display: flex;
    position: absolute;
    background-color: transparent;
    align-items: center;
    border: 0;
    padding: 12px;
    z-index: 9999;
}

.btnHighlight {
    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        top: 0;
        left: 0;
        animation: sonar 1s ease-in-out 0.1s infinite;
    }
}

.icon {
    position: relative;
}

.imageArea {
    margin: 0 -16px;

    & > img {
        width: 100%;
        height: auto;
        margin-bottom: 12px;
    }
}

@keyframes sonar {
    0% {
        opacity: 0;
        transform: scale(0.2);
    }
    40% {
        opacity: 0.8;
        box-shadow: 0 0 0 2px var(--color-secondary-lightness),
            0 0 4px 4px var(--color-secondary-lightness), 0 0 0 4px var(--color-secondary-lightness);
    }
    100% {
        box-shadow: 0 0 0 1px var(--color-secondary-lightness),
            0 0 1px 1px var(--color-secondary-lightness), 0 0 0 1px var(--color-secondary-lightness);
        transform: scale(1.2);
        opacity: 0;
    }
}
