//@import '../theme/_colors.scss';
%border-box {
    box-sizing: border-box;
}

%flex {
    display: flex;
}

%flex-center-all {
    align-items: center;
    justify-content: center;
    align-content: center;
}

%relative {
    position: relative;
}

%absolute {
    position: absolute;
}

%clearfix {

    &::after,
    &::before {
        display: block;
        content: '';
        clear: both;
    }
}

%transition {
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

%textHighlight {
    color: var(--color-warning);
}

%gradient-top-bottom {
    background: var(--color-primary);
    background: linear-gradient(to bottom,
        var(--color-primary) 0%,
        var(--color-primary-darkness) 100%);
}

%gradient-top-left-bottom-right {
    background: var(--color-primary);
    background: linear-gradient(135deg,
        var(--color-primary) 0%,
        var(--color-primary-darkness) 100%);
}

%text-shadow {
    text-shadow: 0px 1px 1px var(--color-black-30);
}

%box-shadow {
    box-shadow: 0px 4px 29px 0px var(--color-black-30);
}

%icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    shape-rendering: geometricprecision;
    -webkit-tap-highlight-color: transparent;
}

%wrap {
    white-space: nowrap;
    display: inline-block;
    position: relative;
}

%inputroot {
    display: inline-block;
}

%inputwrap {
    display: inline-block;
    font-size: inherit;
    padding: 0 0 0 0.2em;
    margin: 0;
    border: 0;
    -webkit-tap-highlight-color: transparent;
}

%inputdata {
    display: inline-block;
    font-size: inherit;
    padding: 0 0 0 0.2em;
    margin: 0;
    border: 0;
    -webkit-tap-highlight-color: transparent;
}

%inputicon {
    display: inline-block;
    position: relative;
    top: 0.1em;
}

%inputlabel {
    display: inline-block;
    line-height: normal;
    cursor: default;
}

%shadow_1 {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

%shadow_2 {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

// %gradient-left-right {
//     background: $primary;
//     background: linear-gradient(to right, $primary 0%, $primary-dark 100%);
// }