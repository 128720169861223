@font-face {
    font-family: "Montserrat";
    src: local('Montserrat'), url("../../fonts/Montserrat-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: "Montserrat";
    src: local('Montserrat'), url("../../fonts/Montserrat-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: "Montserrat";
    src: local('Montserrat'), url("../../fonts/Montserrat-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: local('Montserrat'), url("../../fonts/Montserrat-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

  
@font-face {
    font-family: "Montserrat";
    src: local('Montserrat'), url("../../fonts/Montserrat-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: local('Montserrat'), url("../../fonts/Montserrat-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: "Montserrat";
    src: local('Montserrat'), url("../../fonts/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: local('Montserrat'), url("../../fonts/Montserrat-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: "Montserrat";
    src: local('Montserrat'), url("../../fonts/Montserrat-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Montserrat";
    src: local('Montserrat'), url("../../fonts/Montserrat-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

/* Definições de tipografia */
.theme-primary {}

.theme-secondary {}