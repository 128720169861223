@import '../../../assets/style/_core.scss';

.root {
    position: relative; // margin: $spacing; //padding: $spacing * 2;
    height: 100%;
    @extend %flex;
    flex-direction: column;
    align-items: center;
}

// .wrap {
//     position: absolute;
//     left: 50%;
// }

.center {
    justify-content: center;
}

.top {
    justify-content: start;
}
