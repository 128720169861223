@keyframes zoomOutRight {
    40% {
        opacity: 0.9;
        transform: scale3d(0.9, 0.9, 0.9) translate3d(0, 0, 0);
    }

    to {
        opacity: 0;
        transform: scale3d(0.9, 0.9, 0.9) translate3d(100vw, 0, 0);
        transform-origin: right center;
    }
}

.zoomOutRight {
    animation-name: zoomOutRight;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    animation-name: fadeInUp;
}

.animated {
    animation-duration: 500ms;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.root {
    position: relative;
    overflow-y: scroll;
    height: 100%;
}
// TODO: animações completas
// .appear {}

.appearActive {
    @extend .animated;
    @extend .fadeInUp;
}

// .appearDone {}

// .enter {}

.enterActive {
    @extend .animated;
    @extend .fadeInUp;
}

// .enterDone {}

// .exit {}

.exitActive {
    @extend .animated;
    @extend .zoomOutRight;
}

// .exitDone {}
