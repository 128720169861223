@import '../../assets/style/_core.scss';
.root {
    position: relative;
    display: grid;
    width: 100vw;
    height: 100%;
    overflow: hidden;
    grid-template-areas: 'appbar' 'content';
    grid-template-rows: 44px calc(100% - 44px);
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    overflow-y: auto;
}

.appbarArea {
    grid-area: appbar;
    position: relative;
}

.contentArea {
    grid-area: content;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0 auto;
    width: 100%;
    @include mq('sm') {
        width: 65%;
    }

    @include mq('md') {
        width: 40%;
    }

    @include mq('lg') {
        width: 30%;
    }

    @include mq('xl') {
        width: 25%;
    }
}

.hero {
    height: 50%;
    position: relative;
    padding: $spacing * 2;
    transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.formArea {
    position: relative;
    padding: 0 $spacing * 2;
}

.signInForm {
    height: 100%;
    position: relative;
}

.logoWrap {
    width: 100%;
    height: 60px;
    position: absolute;
    & > svg {
        // hack para safari
        position: absolute;
        display: block;
    }
}

.logoWrapUsername {
    animation: positionUsername 0.3s cubic-bezier(0.4, 0, 0.2, 1) both;
}

.logoWrapUsernamePassword {
    animation: positionUsernamePassword 0.4s cubic-bezier(0.4, 0, 0.2, 1) both;
}

.logoWrapUsernameRegister {
    animation: positionUsernameCadastro 0.3s cubic-bezier(0.4, 0, 0.2, 1) both;
}

.logoWrapPassword {
    animation: positionPassword 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.1s;
}

.logoWrapRegister {
    height: 30px;
    animation: positionCadastro 0.8s cubic-bezier(0.4, 0, 0.2, 1) both;
}

.logo {
    width: auto;
    height: 100%;
    fill: url(#logo-gradient) var(--color-primary);
}

.logoBig {
    transform: scale(1);
}

.logoSmall {
    transform: scale(0.5);
}

.slogan {
    line-height: 0.85 !important;
    padding-bottom: $spacing;
    position: absolute;
    bottom: 56px;
    b {
        @include fontSize(25px);
    }
}

.sloganOffset {
    animation: offset 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
}

.sloganInset {
    animation: inset 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.1s;
}

.callout {
    position: absolute;
    bottom: 16px;
}

.calloutInset {
    animation: inset 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.1s;
}

.calloutOffset {
    animation: offset 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.1s;
}

.viewTitle {
    line-height: 0.85 !important;
    padding-bottom: $spacing;
    position: absolute;
    bottom: 40px;
    b {
        @include fontSize(25px);
    }
}

.titleInset {
    animation: insetTitle 0.4s cubic-bezier(0.4, 0, 0.2, 1) both;
}

.titleOffset {
    animation: offsetTitle 0.4s cubic-bezier(0.4, 0, 0.2, 1) both;
}

.username {
    position: absolute;
    bottom: 16px;
}

.usernameInset {
    animation: insetUsername 0.4s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.1s;
}

.usernameOffset {
    animation: offsetUsername 0.4s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.1s;
}

.openServices {
    grid-area: openServices;
    position: relative;
    padding-top: $spacing;
}

.btnFacebook {
    background-color: #3b5998 !important;
    color: var(--color-white-90) !important;
}

.btnGoogle {
    background-color: #dd4b39 !important;
    color: var(--color-white-90) !important;
}

.marginRight {
    margin-right: $spacing;
}

.inputIcon {
    color: var(--color-secondary-lightness);
}

.form {
    height: 100%;
    position: relative;
}

.buttonWrapper {
    position: relative;
}

.buttonProgress {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
}

.step {
    padding: 0 16px 60px 16px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    @include mq('phone-wide') {
    }
}

.headUsername {
    width: 100%;
}

.headPassword {
    width: 100%;
}

.headRegister {
    height: 135px;
}

.formGroup {
    padding-bottom: 8px;
    position: absolute;
    width: 100%;
}

// LoginUsername
.formUsername {
    position: relative;
    width: 100%;
}

.inputUsernameInset {
    top: 0;
    animation: inset 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.2s;
}

.inputUsernameOffset {
    top: 0;
    animation: offset 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.2s;
}

.btnNextInset {
    top: 88px;
    animation: inset 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.3s;
}

.btnNextOffset {
    top: 88px;
    animation: offset 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.3s;
}

// LoginPassword
.formPassword {
    position: relative;
    width: 100%;
}

.inputPasswordOff {
    top: 0;
    animation: insetToOff 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.2s;
}

.inputPasswordIn {
    top: 0;
    animation: offsetToIn 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.2s;
}

.btnDoneInset {
    top: 88px;
    animation: offsetToIn 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.3s;
}

.btnDoneOffset {
    top: 88px;
    animation: insetToOff 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.3s;
}

.btnRememberInset {
    top: 132px;
    animation: offsetToIn 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.4s;
}

.btnRememberOffset {
    top: 132px;
    animation: insetToOff 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.4s;
}

// LoginCadastro
.formRegister {
    padding: 16px 0;
    height: 320px;
    position: relative;
    display: none;
    width: 100%;
    top: 0;
}

.formRegisterBlock {
    display: block;
}

.fieldset {
    position: absolute;
    width: 100%;
}

.fieldsetPersonal {
    height: 85px;
}

.fieldsetSecurity {
    height: 175px;
}

.fieldsetBody {
    position: relative;
    height: 100%;
}

.pessoaisInset {
    top: 0;
    animation: offsetToIn 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.2s;
}

.pessoaisOffset {
    top: 0;
    animation: insetToOff 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.2s;
}

.segurancaInset {
    top: 96px;
    animation: offsetToIn 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.6s;
}

.segurancaOffset {
    top: 194px;
    animation: insetToOff 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.6s;
}

.inputNameInset {
    top: 0;
    animation: offsetToIn 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.3s;
}

.inputNameOffset {
    top: 0;
    animation: insetToOff 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.3s;
}

.inputLastNameInset {
    top: 72px;
    animation: offsetToIn 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.4s;
}

.inputLastNameOffset {
    top: 72px;
    animation: insetToOff 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.4s;
}

.inputPasswordInset {
    top: 0;
    animation: offsetToIn 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.7s;
}

.inputPasswordOffset {
    top: 0;
    animation: insetToOff 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.7s;
}

.inputConfirmPasswordInset {
    top: 78px;
    animation: offsetToIn 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.8s;
}

.inputConfirmPasswordOffset {
    top: 78px;
    animation: insetToOff 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.8s;
}

.btnRegisterInset {
    top: 272px;
    animation: offsetToIn 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.9s;
}

.btnRegisterOffset {
    top: 400px;
    animation: insetToOff 0.5s cubic-bezier(0.4, 0, 0.2, 1) both;
    animation-delay: 0.9s;
}

// Animations
@keyframes offset {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-100vw, 0, 0);
    }
}

@keyframes inset {
    0% {
        transform: translate3d(-100vw, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes insetToOff {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(100vw, 0, 0);
    }
}

@keyframes offsetToIn {
    0% {
        transform: translate3d(100vw, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes offsetTitle {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(100vw, 0, 0);
    }
}

@keyframes insetTitle {
    0% {
        transform: translate3d(100vw, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes positionPassword {
    0% {
        bottom: 136px;
    }
    100% {
        bottom: 88px;
    }
}

@keyframes positionUsername {
    0% {
        left: -300px;
        bottom: 136px;
        height: 60px;
    }
    100% {
        left: 16px;
        bottom: 136px;
        height: 60px;
    }
}

@keyframes positionUsernamePassword {
    0% {
        bottom: 88px;
    }
    100% {
        bottom: 136px;
    }
}

@keyframes positionUsernameCadastro {
    0% {
        bottom: 88px;
        height: 30px;
    }
    100% {
        bottom: 136px;
        height: 60px;
    }
}

@keyframes positionCadastro {
    0% {
        bottom: 136px;
        height: 60px;
    }
    10% {
        bottom: 130px;
    }
    20% {
        bottom: 122px;
    }
    30% {
        bottom: 120px;
    }
    40% {
        bottom: 116px;
    }
    50% {
        bottom: 110px;
    }
    60% {
        bottom: 102px;
    }
    70% {
        bottom: 96px;
    }
    80% {
        bottom: 90px;
    }
    90% {
        bottom: 88px;
        height: 30px;
    }
    100% {
        bottom: 88px;
        height: 30px;
    }
}

@keyframes offsetCallout {
    0% {
    }
    100% {
    }
}

@keyframes insetUsername {
    0% {
        transform: translate3d(100vw, 0, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes offsetUsername {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(100vw, 0, 0);
    }
}
